import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const EpisodeList = React.lazy(() => import("@/pages/Episode/EpisodeList"));
const EpisodeCreate = React.lazy(() => import("@/pages/Episode/EpisodeCreate"));
const EpisodeEdit = React.lazy(() => import("@/pages/Episode/EpisodeEdit"));

export const EPISODE_ROUTES = {
  episodes: {
    path: "episodes",
    childRoutes: {
      episodeList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episode],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <EpisodeList />,
      },
      episodeCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episode],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <EpisodeCreate />,
      },
      episodeEdit: {
        path: ":episodeId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episode],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <EpisodeEdit />,
      },
    },
  },
};
