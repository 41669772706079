import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const LevelOrderLanding = React.lazy(
  () => import("@/pages/LevelOrder/LevelOrderLanding"),
);

export const LEVEL_ORDER_ROUTES = {
  levelOrder: {
    path: "level-order",
    childRoutes: {
      levelOrderLanding: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelOrder],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelOrderLanding />,
      },
    },
  },
};
