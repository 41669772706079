import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const TransactionModifierTemplateList = React.lazy(
  () =>
    import(
      "@kwaleeltd/casual-admin-core/pages/TransactionModifierTemplate/TransactionModifierTemplateList"
    ),
);
const TransactionModifierTemplateCreate = React.lazy(
  () =>
    import(
      "@/pages/TransactionModifierTemplate/TransactionModifierTemplateCreate"
    ),
);
const TransactionModifierTemplateEdit = React.lazy(
  () =>
    import(
      "@/pages/TransactionModifierTemplate/TransactionModifierTemplateEdit"
    ),
);

export const TRANSACTION_MODIFIER_TEMPLATE_ROUTES = {
  transactionModifierTemplates: {
    path: "transaction-modifier-templates",
    childRoutes: {
      transactionModifierTemplateList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.transactionModifierTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TransactionModifierTemplateList />,
      },
      transactionModifierTemplateCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.transactionModifierTemplate],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <TransactionModifierTemplateCreate />,
      },
      transactionModifierTemplateEdit: {
        path: ":transactionModifierTemplateId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.transactionModifierTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TransactionModifierTemplateEdit />,
      },
    },
  },
};
