import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";
import { LIVE_OPS_ROUTES as BASE_LIVE_OPS_ROUTES } from "@kwaleeltd/casual-admin-core/routes/liveOps";
const SegmentOverrideList = React.lazy(
  () => import("@/pages/LiveOps/SegmentOverrides/SegmentOverridesList"),
);

export const LIVE_OPS_ROUTES = {
  ...BASE_LIVE_OPS_ROUTES,
  liveOps: {
    ...BASE_LIVE_OPS_ROUTES.liveOps,
    childRoutes: {
      ...BASE_LIVE_OPS_ROUTES.liveOps.childRoutes,
      segmentOverrideList: {
        path: "segment-overrides",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.segment],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <SegmentOverrideList />,
      },
    },
  },
};
