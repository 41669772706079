import React from "react";

import { AUDIT_ROUTES as BASE_AUDIT_ROUTES } from "@kwaleeltd/casual-admin-core/routes/audit";

const PlayerProfile = React.lazy(
  () => import("@/pages/Audit/Player/PlayerProfile"),
);
const PlayerLevelProgress = React.lazy(
  () => import("@/pages/Audit/Player/LevelProgress/PlayerLevelProgress"),
);
const PlayerEpisodeProgressLanding = React.lazy(
  () =>
    import("@/pages/Audit/Player/EpisodeProgress/PlayerEpisodeProgressLanding"),
);
const PlayerEpisodeProgress = React.lazy(
  () => import("@/pages/Audit/Player/EpisodeProgress/PlayerEpisodeProgress"),
);
const EpisodeClaimedRewards = React.lazy(
  () => import("@/pages/Audit/Player/EpisodeProgress/ClaimedRewards"),
);
const Tasks = React.lazy(
  () => import("@/pages/Audit/Player/EpisodeProgress/Tasks"),
);
const BoosterDeductions = React.lazy(
  () => import("@/pages/Audit/Player/LevelProgress/BoosterDeductions"),
);
const TollDeductions = React.lazy(
  () => import("@/pages/Audit/Player/LevelProgress/TollDeductions"),
);
const ClaimedRewards = React.lazy(
  () => import("@/pages/Audit/Player/LevelProgress/ClaimedRewards"),
);
const LevelResults = React.lazy(
  () => import("@/pages/Audit/Player/LevelProgress/LevelResults"),
);

const AUDIT_ROUTES = {
  ...BASE_AUDIT_ROUTES,
  audit: {
    ...BASE_AUDIT_ROUTES.audit,
    childRoutes: {
      ...BASE_AUDIT_ROUTES.audit.childRoutes,
      player: {
        ...BASE_AUDIT_ROUTES.audit.childRoutes.player,
        childRoutes: {
          ...BASE_AUDIT_ROUTES.audit.childRoutes.player.childRoutes,
          playerProfile: {
            ...BASE_AUDIT_ROUTES.audit.childRoutes.player.childRoutes
              .playerProfile,
            element: <PlayerProfile />,
            childRoutes: {
              ...BASE_AUDIT_ROUTES.audit.childRoutes.player.childRoutes
                .playerProfile.childRoutes,
              levelProgress: {
                path: "level-progress",
                element: <PlayerLevelProgress />,
                hasOutlet: true,
                childRoutes: {
                  boosterDeductions: {
                    path: "booster-deductions",
                    element: <BoosterDeductions />,
                  },
                  tollDeductions: {
                    path: "toll-deductions",
                    element: <TollDeductions />,
                  },
                  claimedRewards: {
                    path: "claimed-rewards",
                    element: <ClaimedRewards />,
                  },
                  levelResults: {
                    path: "level-results",
                    element: <LevelResults />,
                  },
                },
              },
              episodeProgressLanding: {
                path: "episode-progress",
                element: <PlayerEpisodeProgressLanding />,
                hasOutlet: true,
                childRoutes: {
                  episodeProgress: {
                    path: ":episodeId",
                    element: <PlayerEpisodeProgress />,
                    hasOutlet: true,
                    childRoutes: {
                      episodeClaimRewards: {
                        path: "claimed-rewards",
                        element: <EpisodeClaimedRewards />,
                      },
                      tasks: {
                        path: "tasks",
                        element: <Tasks />,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export { AUDIT_ROUTES };
