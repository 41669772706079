import React from "react";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { store } from "@kwaleeltd/casual-admin-core/app/store";
import { BrowserRouter } from "@kwaleeltd/casual-admin-core/utils/BrowserRoute";

import App from "./App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
