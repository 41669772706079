import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const TileEventList = React.lazy(
  () => import("@kwaleeltd/casual-admin-core/pages/TileEvent/TileEventList"),
);
const TileEventEdit = React.lazy(
  () => import("@/pages/TileEvent/TileEventEdit"),
);

export const TILE_EVENT_ROUTES = {
  tileEvents: {
    path: "tile-events",
    childRoutes: {
      tileEventList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.tileEvent],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TileEventList />,
      },
      tileEventEdit: {
        path: ":tileEventId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.tileEvent],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TileEventEdit />,
      },
    },
  },
};
