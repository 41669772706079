import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const AssetList = React.lazy(() => import("@/pages/Asset/AssetList"));
const AssetCreate = React.lazy(() => import("@/pages/Asset/AssetCreate"));
const AssetEdit = React.lazy(() => import("@/pages/Asset/AssetEdit"));

export const ASSET_ROUTES = {
  assets: {
    path: "assets",
    childRoutes: {
      assetList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.asset],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetList />,
      },
      assetCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.asset],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <AssetCreate />,
      },
      assetEdit: {
        path: ":assetId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.asset],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetEdit />,
      },
    },
  },
};
