import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const LevelTemplateList = React.lazy(
  () => import("@/pages/LevelTemplate/LevelTemplateList"),
);
const LevelTemplateCreate = React.lazy(
  () => import("@/pages/LevelTemplate/LevelTemplateCreate"),
);
const LevelTemplateEdit = React.lazy(
  () => import("@/pages/LevelTemplate/LevelTemplateEdit"),
);

export const LEVEL_TEMPLATE_ROUTES = {
  levelTemplates: {
    path: "level-templates",
    childRoutes: {
      levelTemplateList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelTemplateList />,
      },
      levelTemplateCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelTemplate],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <LevelTemplateCreate />,
      },
      levelTemplateEdit: {
        path: ":levelTemplateId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelTemplateEdit />,
      },
    },
  },
};
