import React from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { useAppSelector } from "@kwaleeltd/casual-admin-core/app/hooks";
import { useGetCurrentUserQuery } from "@kwaleeltd/casual-admin-core/app/services/user";
import { storePersistor } from "@kwaleeltd/casual-admin-core/app/store";
import ProtectedRoute from "@kwaleeltd/casual-admin-core/components/ProtectedRoute";
import { selectIsAuthenticated } from "@kwaleeltd/casual-admin-core/features/auth/authSlice";
import { selectActiveSelection } from "@kwaleeltd/casual-admin-core/features/general/generalSlice";
import Notifier from "@kwaleeltd/casual-admin-core/features/notifier/Notifier";
import { getAppRoutes } from "@kwaleeltd/casual-admin-core/routes/appRoutes";
import Theme from "@kwaleeltd/casual-admin-core/theme/Theme";
import { usePrevious } from "@kwaleeltd/casual-admin-core/utils/usePrevious";

import { ROUTES } from "@/routes/appRoutes";

const Layout = React.lazy(() => import("@/components/Layout/Layout"));

const Login = React.lazy(
  () => import("@kwaleeltd/casual-admin-core/pages/Login/Login"),
);
const ErrorPage = React.lazy(
  () => import("@kwaleeltd/casual-admin-core/pages/Error/ErrorPage"),
);

function App() {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const activeSelection = useAppSelector(selectActiveSelection);

  useGetCurrentUserQuery(undefined, {
    skip:
      !isAuthenticated ||
      (isAuthenticated && prevLocation?.pathname === "/login"),
    refetchOnMountOrArgChange: true,
  });

  return (
    <Theme>
      <PersistGate loading={null} persistor={storePersistor}>
        <Notifier>
          <React.Suspense>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/:gameCode"
                element={
                  <ProtectedRoute
                    hasPermission={isAuthenticated}
                    noPermissionPath="/login"
                  >
                    <Layout />
                  </ProtectedRoute>
                }
              >
                {getAppRoutes(ROUTES, activeSelection)}
              </Route>
              <Route
                path="/"
                element={<Navigate to={`/${activeSelection.code}`} />}
              />
              <Route
                path="*"
                element={<ErrorPage error="Page not found..." />}
              />
            </Routes>
          </React.Suspense>
        </Notifier>
      </PersistGate>
    </Theme>
  );
}

export default App;
