import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const EpisodeOrderEdit = React.lazy(
  () => import("@/pages/EpisodeOrder/EpisodeOrderEdit"),
);

export const EPISODE_ORDER_ROUTES = {
  episodeOrder: {
    path: "episode-order",
    childRoutes: {
      episodeOrderEdit: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episode],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <EpisodeOrderEdit />,
      },
    },
  },
};
