import { ROUTES as BASE_ROUTES } from "@kwaleeltd/casual-admin-core/routes/appRoutes";

import { ASSET_ROUTES } from "@/routes/asset";
import { ASSET_TYPE_ROUTES } from "@/routes/assetType";
import { AUDIT_ROUTES } from "@/routes/audit";
import { EPISODE_ROUTES } from "@/routes/episode";
import { EPISODE_ORDER_ROUTES } from "@/routes/episodeOrder";
import { LEVEL_ROUTES } from "@/routes/level";
import { LEVEL_ORDER_ROUTES } from "@/routes/levelOrder";
import { LEVEL_TEMPLATE_ROUTES } from "@/routes/levelTemplate";
import { LIVE_OPS_ROUTES } from "@/routes/liveOps";
import { SEGMENT_ROUTES } from "@/routes/segment";
import { TASK_ROUTES } from "@/routes/task";
import { TILE_EVENT_ROUTES } from "@/routes/tileEvent";
import { TILE_TEMPLATE_ROUTES } from "@/routes/tileTemplate";
import { TRANSACTION_MODIFIER_TEMPLATE_ROUTES } from "@/routes/transactionModifierTemplate";

/**
 * To add more routes, add key/value pair to this object
 * similar to @kwaleeltd/casual-admin-core/routes/appRoutes
 */
export const ROUTES = {
  ...BASE_ROUTES,
  ...ASSET_ROUTES,
  ...ASSET_TYPE_ROUTES,
  ...EPISODE_ROUTES,
  ...EPISODE_ORDER_ROUTES,
  ...LEVEL_ROUTES,
  ...LEVEL_ORDER_ROUTES,
  ...LEVEL_TEMPLATE_ROUTES,
  ...TASK_ROUTES,
  ...TILE_EVENT_ROUTES,
  ...TILE_TEMPLATE_ROUTES,
  ...TRANSACTION_MODIFIER_TEMPLATE_ROUTES,
  ...SEGMENT_ROUTES,
  ...AUDIT_ROUTES,
  ...LIVE_OPS_ROUTES,
};
