import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const TileTemplateList = React.lazy(
  () =>
    import("@kwaleeltd/casual-admin-core/pages/TileTemplate/TileTemplateList"),
);
const TileTemplateCreate = React.lazy(
  () => import("@/pages/TileTemplate/TileTemplateCreate"),
);
const TileTemplateEdit = React.lazy(
  () => import("@/pages/TileTemplate/TileTemplateEdit"),
);

export const TILE_TEMPLATE_ROUTES = {
  tileTemplates: {
    path: "tile-templates",
    childRoutes: {
      tileTemplateList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.tileTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TileTemplateList />,
      },
      tileTemplateCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.tileTemplate],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <TileTemplateCreate />,
      },
      tileTemplateEdit: {
        path: ":tileTemplateId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.tileTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TileTemplateEdit />,
      },
    },
  },
};
