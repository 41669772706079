import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const SegmentList = React.lazy(
  () => import("@kwaleeltd/casual-admin-core/pages/Segment/SegmentList"),
);
const SegmentCreate = React.lazy(() => import("@/pages/Segment/SegmentCreate"));
const SegmentEdit = React.lazy(() => import("@/pages/Segment/SegmentEdit"));

export const SEGMENT_ROUTES = {
  segments: {
    path: "segments",
    childRoutes: {
      segmentList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.segment],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <SegmentList />,
      },
      segmentCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.segment],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <SegmentCreate />,
      },
      segmentEdit: {
        path: ":segmentId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.segment],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <SegmentEdit />,
      },
    },
  },
};
