import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const AssetTypeList = React.lazy(
  () => import("@/pages/AssetType/AssetTypeList"),
);
const AssetTypeCreate = React.lazy(
  () => import("@/pages/AssetType/AssetTypeCreate"),
);
const AssetTypeEdit = React.lazy(
  () => import("@/pages/AssetType/AssetTypeEdit"),
);

export const ASSET_TYPE_ROUTES = {
  assetTypes: {
    path: "asset-types",
    childRoutes: {
      assetTypeList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.assetType],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetTypeList />,
      },
      assetTypeCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.assetType],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <AssetTypeCreate />,
      },
      assetTypeEdit: {
        path: ":assetTypeId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.assetType],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetTypeEdit />,
      },
    },
  },
};
