import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const TaskList = React.lazy(() => import("@/pages/Task/TaskList"));
const TaskCreate = React.lazy(() => import("@/pages/Task/TaskCreate"));
const TaskEdit = React.lazy(() => import("@/pages/Task/TaskEdit"));

export const TASK_ROUTES = {
  tasks: {
    path: "tasks",
    childRoutes: {
      taskList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.task],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TaskList />,
      },
      taskCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.task],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <TaskCreate />,
      },
      taskEdit: {
        path: ":taskId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.task],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TaskEdit />,
      },
    },
  },
};
