import React from "react";

import {
  RESOURCES,
  RESOURCE_OPERATIONS,
} from "@kwaleeltd/casual-admin-core/models/user";

const LevelList = React.lazy(() => import("@/pages/Level/LevelList"));
const LevelCreate = React.lazy(() => import("@/pages/Level/LevelCreate"));
const LevelEdit = React.lazy(() => import("@/pages/Level/LevelEdit"));

export const LEVEL_ROUTES = {
  levels: {
    path: "levels",
    childRoutes: {
      levelList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.level],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelList />,
      },
      levelCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.level],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <LevelCreate />,
      },
      levelEdit: {
        path: ":levelId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episode],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelEdit />,
      },
    },
  },
};
